import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
// Link 컴포넌트 import 제거

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  background: linear-gradient(45deg, #FFD700, #00CED1, #20B2AA, #FFD700);
  background-size: 400% 400%;
  background-position: 0% 50%;
  box-shadow: 0 4px 12px rgba(138, 43, 226, 0.1);
  position: relative;
  z-index: 100;
  border-bottom: 1px solid rgba(138, 43, 226, 0.2);
  font-family: 'Cinzel', serif;
`;

// Logo 컴포넌트를 a 태그로 수정
const Logo = styled.a`
  font-size: 2.2rem;
  font-weight: bold;
  color: #8a2be2;
  text-decoration: none;
  font-family: 'Cinzel', serif;
  margin-bottom: 1.2rem;
  text-shadow: 1px 1px 2px rgba(138, 43, 226, 0.2);
  
  &:hover {
    color: #6a0dad;
  }
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
  
  @media (max-width: 375px) {
    font-size: 1.5rem;
  }
`;

// DropdownItem 컴포넌트를 a 태그로 수정
const DropdownItem = styled.a`
  display: block;
  padding: 0.7rem 1.2rem;
  text-decoration: none;
  color: #333;
  font-family: 'Cinzel', serif;
  border-left: 0 solid transparent;
  transition: border-left 0.2s, background-color 0.2s, color 0.2s;
  
  &:hover, &:focus {
    background-color: #f5f0ff;
    color: #8a2be2;
    outline: none;
    border-left: 0.3rem solid #8a2be2;
  }
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
    border-left-width: 0;
    
    &:hover, &:focus {
      border-left-width: 0.3rem;
    }
  }
  
  @media (max-width: 375px) {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NavSection = styled.div`
  position: relative;
  margin: 0 2.5rem;
  
  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const NavTrigger = styled.button`
  background: none;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  color: #6a0dad;
  border-radius: 20px;
  font-weight: 500;
  font-family: 'Cinzel', serif;
  
  &:hover {
    background-color: rgba(138, 43, 226, 0.1);
  }
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  
  @media (max-width: 375px) {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0.8rem 0;
  min-width: 200px;
  display: block;
  transform-origin: top center;
  opacity: 1;
  transform: translateY(0);
  
  @media (max-width: 768px) {
    position: relative;
    box-shadow: none;
    width: 100%;
    min-width: 150px;
  }
`;

const Header = () => {
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  
  const navRef = useRef(null);
  
  // 첫 번째 드롭다운 아이템에 포커스 설정
  const setInitialFocus = useCallback((menuType) => {
    setTimeout(() => {
      const menu = document.querySelector(`[data-menu="${menuType}"]`);
      if (menu) {
        const firstItem = menu.querySelector('[role="menuitem"]');
        if (firstItem) {
          firstItem.focus();
        }
      }
    }, 10);
  }, []);

  const toggleLanguageMenu = useCallback(() => {
    const newState = !languageMenuOpen;
    setLanguageMenuOpen(newState);
    setMainMenuOpen(false);
    if (newState) {
      setInitialFocus('language');
    }
  }, [languageMenuOpen, setInitialFocus]);
  
  const toggleMainMenu = useCallback(() => {
    const newState = !mainMenuOpen;
    setMainMenuOpen(newState);
    setLanguageMenuOpen(false);
    if (newState) {
      setInitialFocus('main');
    }
  }, [mainMenuOpen, setInitialFocus]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setLanguageMenuOpen(false);
        setMainMenuOpen(false);
      }
    };

    if (languageMenuOpen || mainMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside, { passive: true });
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageMenuOpen, mainMenuOpen]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setLanguageMenuOpen(false);
      setMainMenuOpen(false);
    }
  }, []);

  return (
    <HeaderContainer>
      <Logo href="https://freetarot.cards/" target="_blank" rel="noopener noreferrer">FreeTarot.Cards</Logo>
      
      <NavContainer ref={navRef}>
        <NavSection>
          <NavTrigger 
            onClick={toggleLanguageMenu} 
            aria-expanded={languageMenuOpen}
            aria-haspopup="true"
            onKeyDown={handleKeyDown}
          >
            Language
          </NavTrigger>
          {languageMenuOpen && (
            <DropdownMenu role="menu" data-menu="language">
              <DropdownItem href="https://freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>English</DropdownItem>
              <DropdownItem href="https://french.freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>French</DropdownItem>
              <DropdownItem href="https://german.freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>German</DropdownItem>
              <DropdownItem href="https://japanese.freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Japanese</DropdownItem>
              <DropdownItem href="https://korean.freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Korean</DropdownItem>
              <DropdownItem href="https://spanish.freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Spanish</DropdownItem>
            </DropdownMenu>
          )}
        </NavSection>
        
        <NavSection>
          <NavTrigger 
            onClick={toggleMainMenu}
            aria-expanded={mainMenuOpen}
            aria-haspopup="true"
            onKeyDown={handleKeyDown}
          >
            Menu
          </NavTrigger>
          {mainMenuOpen && (
            <DropdownMenu role="menu" data-menu="main">
              <DropdownItem href="https://freetarot.cards/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Free Tarot Reading</DropdownItem>
              <DropdownItem href="https://blog.freetarot.cards/blog/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Blog</DropdownItem>
              <DropdownItem href="https://blog.freetarot.cards/about-us/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>About Us</DropdownItem>
              <DropdownItem href="https://blog.freetarot.cards/privacy-policy/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Privacy Policy</DropdownItem>
              <DropdownItem href="https://blog.freetarot.cards/terms-of-service/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Terms Of Service</DropdownItem>
              <DropdownItem href="https://blog.freetarot.cards/contact-us/" target="_blank" rel="noopener noreferrer" role="menuitem" tabIndex={0}>Contact Us</DropdownItem>
            </DropdownMenu>
          )}
        </NavSection>
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header;