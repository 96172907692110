import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// --- animations ---
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const aurora = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// --- styled components ---
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
  background: linear-gradient(45deg, rgba(255,255,255,0.8), rgba(0,206,209,0.6), rgba(32,178,170,0.6), rgba(255,255,255,0.8));
  background-size: 400% 400%;
  animation: ${fadeIn} 0.8s ease-out, ${aurora} 15s ease infinite;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(106,13,173,0.1);
`;

const Title = styled.h1`
  color: #6a0dad;
  margin-bottom: 40px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
`;

const QuestionSection = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const QuestionLabel = styled.h3`
  margin-bottom: 10px;
  color: #6a0dad;
  font-size: 1.3rem;
`;

const Question = styled.div`
  background: #f9f9f9;
  padding: 20px;
  border-left: 5px solid #6a0dad;
  border-radius: 8px;
  font-size: 1.1rem;
`;

const CardsSection = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
  width: 100%;
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
`;

const PositionLabel = styled.h4`
  margin-bottom: 10px;
  color: #6a0dad;
  font-size: 1.1rem;
`;

const CardImage = styled.div`
  width: 120px;
  height: 180px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
  background-image: url(${props => props.src});
  background-size: cover;
  margin-bottom: 10px;
  transform: ${props => props.reversed ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const CardName = styled.h5`
  text-align: center;
  color: #333;
  font-size: 1rem;
`;

const InterpretationSection = styled.div`
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  margin-bottom: 40px;
  width: 100%;
  white-space: pre-line;
  border-left: 5px solid #6a0dad;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);
`;

const InterpretationTitle = styled.h3`
  margin-bottom: 20px;
  color: #6a0dad;
  font-size: 1.4rem;
  border-bottom: 2px solid #e0c1ff;
  padding-bottom: 10px;
`;

const InterpretationText = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  font-size: 1.1rem;
  padding: 15px;
  background-color: #ffebee;
  border-radius: 8px;
`;

const BackButton = styled.button`
  padding: 15px 30px;
  background-color: #6a0dad;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 8px rgba(106,13,173,0.3);

  &:hover {
    background-color: #4b0082;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
  }
`;

// --- component ---
const Interpretation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { question, cards, interpretation, error, uiLanguage } = location.state || {};

  const defaultUiLanguage = {
    title: "Tarot Card Interpretation",
    questionLabel: "Question:",
    interpretationTitle: "Interpretation:",
    errorMessage: "Sorry, there was an error while interpreting. Please try again.",
    loadingMessage: "Generating interpretation...",
    newReadingButton: "New Tarot Reading"
  };

  const ui = uiLanguage || defaultUiLanguage;

  useEffect(() => {
    if (!cards || cards.length === 0) {
      navigate('/');
    }
  }, [cards, navigate]);

  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <Container>
      <Title>{ui.title}</Title>

      <QuestionSection>
        <QuestionLabel>{ui.questionLabel}</QuestionLabel>
        <Question>{question}</Question>
      </QuestionSection>

      <CardsSection>
        {cards.map((card, index) => {
          const position = ['Past', 'Present', 'Future'][index];
          const orientation = card.reversed ? '(Reversed)' : '(Upright)';
          return (
            <CardItem key={index}>
              <PositionLabel>{position}</PositionLabel>
              <CardImage src={card.image} alt={card.name} reversed={card.reversed} />
              <CardName>{card.name} {orientation}</CardName>
            </CardItem>
          );
        })}
      </CardsSection>

      <InterpretationSection>
        <InterpretationTitle>{ui.interpretationTitle}</InterpretationTitle>
        {error ? (
          <ErrorMessage>{ui.errorMessage}</ErrorMessage>
        ) : (
          <InterpretationText>{interpretation || ui.loadingMessage}</InterpretationText>
        )}
      </InterpretationSection>

      <BackButton onClick={() => navigate('/')}>
        {ui.newReadingButton}
      </BackButton>
    </Container>
  );
};

export default Interpretation;
