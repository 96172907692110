import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import QuestionForm from './components/QuestionForm';
import CardSelection from './components/CardSelection';
import Interpretation from './components/Interpretation';
import Header from './components/Header';
import Footer from './components/Footer';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
  }
  
  body {
    background-color: #f8f4ff;
  }
`;

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Router basename={process.env.PUBLIC_URL}>
          <Header />
          <MainContent>
            <Routes>
              <Route path="/" element={<QuestionForm />} />
              <Route path="/cards" element={<CardSelection />} />
              <Route path="/interpretation" element={<Interpretation />} />
            </Routes>
          </MainContent>
          <Footer />
        </Router>
      </AppContainer>
    </>
  );
}

export default App;
