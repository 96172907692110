export const tarotCards = [
  {
    id: 0,
    name: "The Fool",
    koreanName: "바보",
    description: "New beginnings, optimism, trust in life",
    koreanDescription: "새로운 시작, 낙관주의, 삶에 대한 신뢰",
    image: process.env.PUBLIC_URL + "/images/tarot/fool.jpg",
    upright: "New beginnings, adventure, innocence, freedom",
    reversed: "Recklessness, dangerous behavior, carelessness"
  },
  {
    id: 1,
    name: "The Magician",
    koreanName: "마법사",
    description: "Manifestation, resourcefulness, power, inspired action",
    koreanDescription: "실현, 창의성, 힘, 영감을 주는 행동",
    image: process.env.PUBLIC_URL + "/images/tarot/magician.jpg",
    upright: "Creativity, willpower, skill, confidence",
    reversed: "Manipulation, poor planning, inexperience"
  },
  {
    id: 2,
    name: "The High Priestess",
    koreanName: "여사제",
    description: "Intuition, sacred knowledge, divine feminine, subconscious mind",
    koreanDescription: "직관, 신성한 지식, 여성성, 잠재의식",
    image: process.env.PUBLIC_URL + "/images/tarot/highpriestess.jpg", 
    upright: "Intuition, unconscious, inner wisdom",
    reversed: "Secrets, lack of information, confusion"
  },
  {
    id: 3,
    name: "The Empress",
    koreanName: "여황제",
    description: "Femininity, beauty, nature, nurturing, abundance",
    koreanDescription: "여성성, 아름다움, 자연, 양육, 풍요",
    image: process.env.PUBLIC_URL + "/images/tarot/empress.jpg",
    upright: "Abundance, motherhood, creativity, sensual pleasure",
    reversed: "Dependency, overprotection, lack of creativity"
  },
  {
    id: 4,
    name: "The Emperor",
    koreanName: "황제",
    description: "Authority, establishment, structure, father figure",
    koreanDescription: "권위, 확립, 구조, 아버지 상",
    image: process.env.PUBLIC_URL + "/images/tarot/emperor.jpg",
    upright: "Authority, structure, control, fatherhood",
    reversed: "Tyranny, rigidity, lack of control"
  },
  {
    id: 5,
    name: "The Hierophant",
    koreanName: "교황",
    description: "Spiritual wisdom, religious beliefs, conformity, tradition",
    koreanDescription: "영적 지혜, 종교적 신념, 순응, 전통",
    image: process.env.PUBLIC_URL + "/images/tarot/hierophant.jpg",
    upright: "Tradition, convention, spiritual guidance, conformity",
    reversed: "Rebellion, non-traditional approaches, non-conformity"
  },
  {
    id: 6,
    name: "The Lovers",
    koreanName: "연인",
    description: "Love, harmony, relationships, values alignment, choices",
    koreanDescription: "사랑, 조화, 관계, 가치관 일치, 선택",
    image: process.env.PUBLIC_URL + "/images/tarot/lovers.jpg",
    upright: "Love, harmony, relationships, value alignment",
    reversed: "Imbalance, separation, misalignment"
  },
  {
    id: 7,
    name: "The Chariot",
    koreanName: "전차",
    description: "Control, willpower, success, action, determination",
    koreanDescription: "통제, 의지력, 성공, 행동, 결단력",
    image: process.env.PUBLIC_URL + "/images/tarot/chariot.jpg",
    upright: "Willpower, determination, victory, self-control",
    reversed: "Self-doubt, lack of control, aggression"
  },
  {
    id: 8,
    name: "Strength",
    koreanName: "힘",
    description: "Strength, courage, persuasion, influence, compassion",
    koreanDescription: "힘, 용기, 설득, 영향력, 연민",
    image: process.env.PUBLIC_URL + "/images/tarot/strength.jpg",
    upright: "Courage, persuasion, influence, compassion",
    reversed: "Lack of confidence, weakness, vulnerability"
  },
  {
    id: 9,
    name: "The Hermit",
    koreanName: "은둔자",
    description: "Soul-searching, introspection, being alone, inner guidance",
    koreanDescription: "영혼 탐색, 내성, 고독, 내면의 인도",
    image: process.env.PUBLIC_URL + "/images/tarot/hermit.jpg",
    upright: "Inner search, reflection, solitude, guidance",
    reversed: "Isolation, loneliness, withdrawal"
  },
  {
    id: 10,
    name: "Wheel of Fortune",
    koreanName: "운명의 수레바퀴",
    description: "Good luck, karma, life cycles, destiny, a turning point",
    koreanDescription: "행운, 업보, 생명 주기, 운명, 전환점",
    image: process.env.PUBLIC_URL + "/images/tarot/wheel.jpg",
    upright: "Destiny, luck, change, cycles",
    reversed: "Bad luck, resistance, unexpected changes"
  },
  {
    id: 11,
    name: "Justice",
    koreanName: "정의",
    description: "Justice, fairness, truth, cause and effect, law",
    koreanDescription: "정의, 공정함, 진실, 인과관계, 법",
    image: process.env.PUBLIC_URL + "/images/tarot/justice.jpg",
    upright: "Justice, fairness, truth, law",
    reversed: "Imbalance, separation, misalignment"
  },
  {
    id: 12,
    name: "Hanged Man",
    koreanName: "매달린 사람",
    description: "Pause, surrender, letting go, new perspectives",
    koreanDescription: "일시 중지, 항복, 포기, 새로운 관점",
    image: process.env.PUBLIC_URL + "/images/tarot/hangedman.jpg",
    upright: "Sacrifice, new perspective, patience",
    reversed: "Delay, resistance, no milk"
  },
  {
    id: 13,
    name: "Death",
    koreanName: "죽음",
    description: "Endings, change, transformation, transition",
    koreanDescription: "종말, 변화, 변형, 전환",
    image: process.env.PUBLIC_URL + "/images/tarot/death.jpg",
    upright: "End, change, transformation, revolution",
    reversed: "Resistance, delayed change, identity"
  },
  {
    id: 14,
    name: "Temperance",
    koreanName: "절제",
    description: "Balance, moderation, patience, purpose",
    koreanDescription: "균형, 절제, 인내, 목적",
    image: process.env.PUBLIC_URL + "/images/tarot/temperance.jpg",
    upright: "Balance, harmony, tolerance, integration",
    reversed: "Imbalance, excess, discrepancy"
  },
  {
    id: 15,
    name: "Devil",
    koreanName: "악마",
    description: "Shadow self, attachment, addiction, restriction, sexuality",
    koreanDescription: "그림자 자아, 집착, 중독, 제한, 성",
    image: process.env.PUBLIC_URL + "/images/tarot/devil.jpg",
    upright: "Addiction, substance abuse, precaution, self-seduction",
    reversed: "Freedom, division, regain control"
  },
  {
    id: 16,
    name: "Tower",
    koreanName: "탑",
    description: "Sudden change, upheaval, chaos, revelation, awakening",
    koreanDescription: "갑작스러운 변화, 격변, 혼돈, 계시, 각성",
    image: process.env.PUBLIC_URL + "/images/tarot/tower.jpg",
    upright: "Sudden change, chaos, Crisis, self-awareness",
    reversed: "Fear of change, evasion, disaster"
  },
  {
    id: 17,
    name: "Star",
    koreanName: "별",
    description: "Hope, faith, purpose, renewal, spirituality",
    koreanDescription: "희망, 믿음, 목적, 갱신, 영성",
    image: process.env.PUBLIC_URL + "/images/tarot/star.jpg",
    upright: "Hope, inspiration, peace, tolerance",
    reversed: "Despair, gloom, no faith"
  },
  {
    id: 18,
    name: "Moon",
    koreanName: "달",
    description: "Illusion, fear, anxiety, subconscious, intuition",
    koreanDescription: "환상, 두려움, 불안, 잠재의식, 직관",
    image: process.env.PUBLIC_URL + "/images/tarot/moon.jpg",
    upright: "Illusion, intuition, uncertainty, subconscious thought",
    reversed: "Confusion, fear, misunderstanding"
  },
  {
    id: 19,
    name: "Sun",
    koreanName: "태양",
    description: "Positivity, fun, warmth, success, vitality",
    koreanDescription: "긍정, 재미, 따뜻함, 성공, 활력",
    image: process.env.PUBLIC_URL + "/images/tarot/sun.jpg",
    upright: "Joy, success, vitality, clear-headedness",
    reversed: "Overly pessimistic, dissatisfaction, depression"
  },
  {
    id: 20,
    name: "Judgement",
    koreanName: "심판",
    description: "Judgement, rebirth, inner calling, absolution",
    koreanDescription: "심판, 부활, 내면의 소명, 사면",
    image: process.env.PUBLIC_URL + "/images/tarot/judgement.jpg",
    upright: "Rebirth, calling, self-awareness, renewal",
    reversed: "Self doubt, denial, regret"
  },
  {
    id: 21,
    name: "World",
    koreanName: "세계",
    description: "Completion, accomplishment, travel, lesson",
    koreanDescription: "완성, 성취, 여행, 교훈",
    image: process.env.PUBLIC_URL + "/images/tarot/world.jpg",
    upright: "Completion, achievement, integration, travel",
    reversed: "Incomplete, delay, identity"
  }
];

export default tarotCards;