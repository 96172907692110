import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const aurora = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
  padding: 39px 21px;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(0, 206, 209, 0.6), rgba(32, 178, 170, 0.6), rgba(255, 255, 255, 0.8));
  background-size: 400% 400%;
  animation: ${fadeIn} 0.8s ease-out, ${aurora} 15s ease infinite;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #6a0dad;
  margin-bottom: 40px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  width: 99%;
  max-width: 549px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px;
  background: linear-gradient(45deg, #f9f0ff, #e0f7fa, #b2ebf2, #f9f0ff);
  background-size: 400% 400%;
  animation: aurora 15s ease infinite;
  border-radius: 15px;
  box-shadow: 0 9px 21px rgba(106, 13, 173, 0.15);
`;

const TextArea = styled.textarea`
  padding: 18px;
  border-radius: 12px;
  border: 3px solid #e0c1ff;
  font-size: 15px;
  min-height: 60px;
  resize: vertical;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: 'Arial', sans-serif;

  &:focus {
    outline: none;
    border-color: #6a0dad;
    box-shadow: 0 0 9px rgba(106, 13, 173, 0.3);
  }

  &::placeholder {
    color: #999;
  }
`;

const Button = styled.button`
  padding: 15px 25px;
  background-color: #6a0dad;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 8px rgba(106, 13, 173, 0.3);

  &:hover {
    background-color: #4b0082;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(106, 13, 173, 0.4);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(106, 13, 173, 0.4);
  }
`;

const QuestionForm = () => {
  const [question, setQuestion] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalQuestion = question.trim() || 'What does my future hold?';
    navigate('/cards', { state: { question: finalQuestion } });
  };

  return (
    <FormContainer>
      <Title>Get Your Free Tarot Reading!</Title>
      <Form onSubmit={handleSubmit}>
        <TextArea
          placeholder="Type your question here..."
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <Button type="submit">Draw Cards</Button>
      </Form>
    </FormContainer>
  );
};

export default QuestionForm;
