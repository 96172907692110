import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import tarotCards from '../data/tarotCards'; // 경로는 프로젝트 구조에 맞게 조정

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  color: #6a0dad;
  margin-bottom: 30px;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
`;

const SelectionText = styled.p`
  margin: 20px 0;
  font-size: 18px;
  color: #6a0dad;
`;

const SpreadContainer = styled.div`
  position: relative;
  height: 600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SemiCircleRow = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  width: 80px;
  height: 120px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-image: url(${process.env.PUBLIC_URL}/images/tarot/back.jpg);
  background-size: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: absolute;
  transform: rotate(${props => props.rotation}deg) translate(-50%, -50%);
  left: ${props => props.x}%;
  top: ${props => props.y}%;

  &:hover {
    transform: rotate(${props => props.rotation}deg) translate(-50%, -60%);
  }
`;

const SelectedCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const SelectedCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
`;

const PositionLabel = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  color: #6a0dad;
`;

const SelectedCard = styled.div`
  width: 120px;
  height: 180px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  background-image: url(${props => props.image});
  background-size: cover;
  margin-bottom: 10px;
  transform: ${props => props.reversed ? 'rotate(180deg)' : 'rotate(0deg)'};
  animation: ${fadeIn} 0.5s ease-out;
  transition: transform 0.3s ease;
`;

const CardName = styled.div`
  font-size: 14px;
  color: #333;
  margin-top: 10px;
`;

const InterpretButton = styled.button`
  background-color: #6a0dad;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #8a2be2;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingText = styled.div`
  background-color: white;
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 18px;
  color: #6a0dad;
`;

// --------------------------
// Component Logic Starts Here
// --------------------------
const CardSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { question } = location.state || { question: "What does my future hold?" };

  const [selectedCards, setSelectedCards] = useState([]);
  const [spreadCards, setSpreadCards] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cards = [];

    for (let row = 0; row < 3; row++) {
      for (let i = 0; i < 26; i++) {
        const angle = (i / 25) * 180;
        const radians = (angle * Math.PI) / 180;
        const x = 50 + 45 * Math.cos(radians);
        const y = 50 + 20 * Math.sin(radians);
        const rotation = (Math.random() - 0.5) * 20;

        cards.push({
          id: row * 26 + i,
          x,
          y,
          rotation,
          row
        });
      }
    }

    setSpreadCards(cards);
  }, []);

  const handleCardSelect = (cardId) => {
    if (selectedCards.length >= 3 || selectedCards.includes(cardId)) return;

    const availableCards = tarotCards.filter(card =>
      !selectedCards.some(sc => sc.id === card.id)
    );
    const randomCard = availableCards[Math.floor(Math.random() * availableCards.length)];
    const isReversed = Math.random() > 0.5;

    const newCard = {
      ...randomCard,
      reversed: isReversed
    };

    setSelectedCards([...selectedCards, newCard]);
  };

  const handleInterpretation = async () => {
    setLoading(true);

    try {
      const cardNames = selectedCards.map(card =>
        `${card.name}${card.reversed ? ' (Reversed)' : ''}`
      ).join(', ');

      const response = await fetch('/.netlify/functions/interpretTarot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question,
          cards: selectedCards,
          text: cardNames,
          lang: 'en'
        }),
      });

      if (!response.ok) throw new Error('Failed to get interpretation');

      const data = await response.json();

      navigate('/interpretation', {
        state: {
          question,
          cards: selectedCards,
          interpretation: data.interpretation,
          fallback: data.fallback
        }
      });
    } catch (error) {
      console.error('Error getting interpretation:', error);
      navigate('/interpretation', {
        state: {
          question,
          cards: selectedCards,
          error: true
        }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Select 3 Cards!</Title>
      <Subtitle>Pick one card each for Past, Present, and Future.</Subtitle>

      {selectedCards.length !== 3 && (
        <SelectionText>
          Please select {3 - selectedCards.length} more card(s)!
        </SelectionText>
      )}

      <SpreadContainer>
        {[0, 1, 2].map(row => (
          <SemiCircleRow key={row}>
            {spreadCards.filter(card => card.row === row).map((card, index) => (
              <Card
                key={index}
                x={card.x}
                y={card.y}
                rotation={card.rotation}
                onClick={() => handleCardSelect(card.id)}
              />
            ))}
          </SemiCircleRow>
        ))}
      </SpreadContainer>

      {selectedCards.length > 0 && (
        <SelectedCardsContainer>
          {selectedCards.map((card, index) => {
            const position = ['Past', 'Present', 'Future'][index];
            return (
              <SelectedCardWrapper key={index}>
                <PositionLabel>{position}</PositionLabel>
                <SelectedCard image={card.image} reversed={card.reversed} />
                <CardName>{card.name} ({card.reversed ? 'Reversed' : 'Upright'})</CardName>
              </SelectedCardWrapper>
            );
          })}
        </SelectedCardsContainer>
      )}

      {selectedCards.length === 3 && (
        <InterpretButton onClick={handleInterpretation}>
          Get Interpretation
        </InterpretButton>
      )}

      {loading && (
        <LoadingOverlay>
          <LoadingText>Generating your interpretation...</LoadingText>
        </LoadingOverlay>
      )}
    </Container>
  );
};

export default CardSelection;
