import React from 'react';
import styled, { keyframes } from 'styled-components';

const aurora = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const FooterContainer = styled.footer`
  background: linear-gradient(45deg, #FFD700, #00CED1, #20B2AA, #FFD700);
  background-size: 400% 400%;
  animation: ${aurora} 15s ease infinite;
  padding: 2rem 1rem;
  border-top: 1px solid #e0e0e0;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  font-family: 'Cinzel', serif;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 0 1rem;
  
  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
`;

const FooterLink = styled.a`
  display: block;
  color: #555;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  color: #777;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 2rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Language</FooterTitle>
          <FooterLink href="https://freetarot.cards/">English</FooterLink>
          <FooterLink href="https://french.freetarot.cards/">French</FooterLink>
          <FooterLink href="https://german.freetarot.cards/">German</FooterLink>
          <FooterLink href="https://japanese.freetarot.cards/">Japanese</FooterLink>
          <FooterLink href="https://korean.freetarot.cards/">Korean</FooterLink>
          <FooterLink href="https://spanish.freetarot.cards/">Spanish</FooterLink>
        </FooterSection>
        
        <FooterSection>
          <FooterTitle>Menu</FooterTitle>
          <FooterLink href="https://freetarot.cards/">Free Tarot Reading</FooterLink>
          <FooterLink href="https://blog.freetarot.cards/blog/">Blog</FooterLink>
          <FooterLink href="https://blog.freetarot.cards/about-us/">About Us</FooterLink>
          <FooterLink href="https://blog.freetarot.cards/privacy-policy/">Privacy Policy</FooterLink>
          <FooterLink href="https://blog.freetarot.cards/terms-of-service/">Terms Of Service</FooterLink>
          <FooterLink href="https://blog.freetarot.cards/contact-us/">Contact Us</FooterLink>
        </FooterSection>
      </FooterContent>
      
      <Copyright>Copyright 2025 - FreeTarot.Cards</Copyright>
    </FooterContainer>
  );
};

export default Footer;